import React from 'react'

const Header = () => {
  return (
    <div className='HeaderPart'>
      <h2>
        <span>Dominik Kuře</span>
      </h2>
    </div>
  )
}

export default Header
