const Links = () => {
  return (
    <>
      <p style={{ textAlign: 'center' }}>Check out my latest deployed smaller projects: </p>
      <div className='Buttons'>
        <div>
          <a className='link' href='https://todolist.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Todo List</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://hackertyper.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Hacker Typer</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://tictactoe.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Tic Tac Toe</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://musicblog.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Music blog</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://pendulum.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Double pendulum</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://gtpr.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>GTPR (DB mock)</span>
            </button>
          </a>
        </div>
        <div>
          <a
            className='link'
            href='https://reduxcounter.dkure.cz/'
            target='_blank'
            rel='noreferrer'
          >
            <button className='button-64' role='button'>
              <span className='text'>Redux counter</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='https://www.snake.dkure.cz/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>Snake</span>
            </button>
          </a>
        </div>
        <div>
          <a className='link' href='http://www.kured.fun/' target='_blank' rel='noreferrer'>
            <button className='button-64' role='button'>
              <span className='text'>GTPR (PERN stack with Nginx on VPS)</span>
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default Links
