import github from '../Assets/github5.png'
import linkedin from '../Assets/linkedin2.png'
import mail from '../Assets/mail.png'
import vut from '../Assets/vut.jpg'
import yt from '../Assets/youtube.png'

const Icons = () => {
  return (
    <div>
      <a href='mailto:dominikkure@gmail.com' target='blank'>
        <img src={mail} width='50' height='50' />
      </a>
      <a href='https://www.linkedin.com/in/dominik-k-078124ab/' target='_blank' rel='noreferrer'>
        <img src={linkedin} width='50' height='50' />
      </a>
      <a href='https://github.com/Avantiny' target='_blank' rel='noreferrer'>
        <img src={github} width='50' height='50' />
      </a>
      <a href='https://dspace.vutbr.cz/handle/11012/190433' target='blank'>
        <img src={vut} width='50' height='50' />
      </a>
      <a href='https://www.youtube.com/watch?v=NBAKnKCN348&ab_channel=DominikKure' target='blank'>
        <img src={yt} width='50' height='50' />
      </a>
    </div>
  )
}

export default Icons
