import Icons from './Icons'
import Links from './Links'
import logo from '../Assets/dom_new.jpg'

const Midpart = () => {
  return (
    <div className='MidPart'>
      <img
        style={{ textAlign: 'center', border: '2px solid black' }}
        src={logo}
        width='200'
        height='200'
      />
      <p style={{ textAlign: 'center' }}>
        JavaScript/TypeScript full-stack developer from Brno, Czechia
      </p>
      <Icons />
      <Links />
    </div>
  )
}

export default Midpart
